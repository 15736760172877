<template>
  <div class="container">
    <md-card class="dash-content">
      <PageHeader title="Points Management" />

      <!-- Transactions Table -->
      <div class="loader" v-if="loading || updating">
        <Wave width="50px" color="#d9534f" />
      </div>

      <md-table
        v-model="points.mdData"
        md-card
        md-sort="created_at"
        md-sort-order="desc"
      >
        <md-table-toolbar class="mb-4">
          <div class="">
            <!-- <md-field md-clearable class="md-toolbar-section-end">
              <md-input
                placeholder="Search by reference..."
                v-model="search"
                @input="searchTransactions"
              />
            </md-field> -->
            <md-menu md-size="medium" md-align-trigger>
              <md-button
                disabled
                md-menu-trigger
                class="md-primary button-icon"
              >
                Filter
                <img src="@/assets/svg/filter.svg" />
              </md-button>

              <md-menu-content> </md-menu-content>
            </md-menu>
          </div>
        </md-table-toolbar>

        <md-table-empty-state
          v-if="!points.mdData.length && !loading"
          md-label="No transactions found"
          md-description="No point transactions have been recorded yet or none match your current filter."
        >
          <md-button class="md-primary md-raised" @click="fetchTransactions"
            >Refresh</md-button
          >
        </md-table-empty-state>

        <md-table-row slot="md-table-row" slot-scope="{ item, index }">
          <md-table-cell md-label="S/N" class="text-start">{{
            index + 1
          }}</md-table-cell>

          <md-table-cell md-label="User Details">
            <div class="summary-grid">
              <div class="summary-item">
                <div class="value">
                  {{ item.pointable.user.first_name }}
                  {{ item.pointable.user.last_name }}
                </div>
              </div>
              <div class="summary-item">
                (
                <small> {{ item.pointable.company_name }} </small>)
              </div>
            </div>
          </md-table-cell>

          <md-table-cell md-label="Current Point Balance">
            <strong>{{ item.balance | formatNumber }}</strong>
            Points</md-table-cell
          >
          <md-table-cell md-label="Type">
            <span class="badge">
              {{ item.pointable_type }}
            </span>
          </md-table-cell>
          <md-table-cell md-label="Created Date">{{
            item.created_at | formatDate
          }}</md-table-cell>
        </md-table-row>
      </md-table>

      <span v-if="loading" class="b-loading">Loading transactions...</span>
      <pagination
        v-model="points.mdPage"
        :records="points.mdCount"
        :per-page="points.mdPerPage"
        @paginate="updatePagination"
      />
    </md-card>
  </div>
</template>

<script>
import { PageHeader, Wave } from "@/components";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("point");

export default {
  name: "Points",
  components: {
    PageHeader,
    Wave,
  },
  data: () => ({
    search: null,
    fetching: false,
    updating: false,
    exporting: false,
    time: null,
    showStatus: false,
    metadata: true,
    form: {
      point_status: "",
    },
  }),
  watch: {
    search(val) {
      this.waitForIt(val);
    },
  },
  methods: {
    ...mapActions(["getPoints", "updatePointStatus"]),

    async submitSearch() {
      await this.getPointOrders({ keyword: this.search });
    },

    waitForIt(val) {
      if (this.time) {
        clearTimeout(this.time);
      }
      this.time = setTimeout(() => this.submitSearch(val), 1000);
    },
  },

  mounted() {
    this.getPoints({});
  },
  computed: {
    ...mapGetters(["loading", "points"]),
    hasPermission() {
      return this.$store.getters["auth/has_module_permission"]("crud");
    },
  },
};
</script>

<style lang="scss" scoped>
// .md-field {
//   max-width: 500px;
// }
.md-table-cell {
  width: 0% !important;
  padding: 0rem 0.8rem;
}

.md-table + .md-table {
  margin-top: 16px;

  thead {
    height: 50px !important;
  }
}

.badge {
  background: #f3f3f3;

  &.success {
    background: #5cb85c;
  }

  &.danger {
    background: #d9534f;
  }
}

.modal-contain {
  max-width: 100%;
  width: 600px;
  padding: 20px;
  overflow-y: auto;

  .item {
    display: flex;
    gap: 20px;
    padding: 10px 10px;
    margin: 5px 0px;

    &:nth-child(odd) {
      background: #00ff1f0d;
    }
  }
}
.loader {
  position: absolute;
  top: 24px;
  right: 30px;
}

.account-summary {
  margin-bottom: 2rem;
  background-color: #f9f9f9;
}

.summary-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

.summary-item {
  padding: 4px;
}

.summary-item .value {
  font-size: 16px;
  font-weight: 500;
}

.summary-item .value small {
  font-size: 12px;
}

.toolbar-filter-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 15px;
}

.button-icon {
  display: flex;
  align-items: center;
  gap: 5px;
}

.b-loading {
  display: block;
  text-align: center;
  padding: 10px;
  color: #666;
}

.text-center {
  text-align: center;
}

.status-credit {
  color: #28a745;
  font-weight: 500;
}

.status-debit {
  color: #dc3545;
  font-weight: 500;
}

.status-approved {
  color: #28a745;
  font-weight: 500;
}

.status-pending {
  color: #ffc107;
  font-weight: 500;
}

/* User Details Cell Styling */
.user-details {
  display: flex;
  align-items: center;
  gap: 12px;
}

.user-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #d9534f;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
}

.user-info {
  display: flex;
  flex-direction: column;
}

.user-name {
  font-weight: 600;
  color: #333;
  font-size: 14px;
}

.user-email {
  color: #666;
  font-size: 12px;
  margin: 2px 0;
}

.user-company {
  color: #888;
  font-size: 12px;
  font-style: italic;
}
</style>
